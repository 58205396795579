.w-196{
  width: 196px;
}

.filter .dataTable-top {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  gap: 1rem 0;
}

.filter .dataTable-top:after {
  display: none;
}